<template>
  <div class="text-center" v-if="loading">
    <v-progress-circular indeterminate color="primary"></v-progress-circular>
  </div>
  <v-card v-else>
    <v-toolbar>
      <v-toolbar-title>Claim Detail - {{ claim.claimKey }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <small
        >{{ $i18n.translate("Submitted") }}
        {{ claim.submittedDate | formatDateClient("MM/DD/YYYY hh:mm a z", selectedClient) }} ({{
          claim.submittedDate | formatDateFromNow
        }})</small
      >

      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn
            fab
            small
            class="mx-2"
            v-on="on"
            @click="
              prevClaim();
              byPage = true;
            "
            :disabled="currentPosition == 0"
          >
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
        </template>
        <span>{{ $i18n.translate("Prev") }}</span>
      </v-tooltip>
      <small> {{ showPositionInfo }} </small>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn
            fab
            small
            class="mx-2"
            v-on="on"
            @click="
              nextClaim();
              byPage = true;
            "
            :disabled="disableNextButton"
          >
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </template>
        <span>{{ $i18n.translate("Next") }}</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn
            :disabled="claim.approved || claim.closed"
            fab
            small
            class="ml-2"
            v-on="on"
            @click="approveClaimDialog = true"
          >
            <v-icon color="green">mdi-thumb-up</v-icon>
          </v-btn>
        </template>
        <span>{{ $i18n.translate("Approve") }} {{ $i18n.translate("Claim") }}</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn
            :disabled="claim.rejected || claim.closed"
            fab
            small
            class="ml-2"
            v-on="on"
            @click="rejectClaimDialog = true"
          >
            <v-icon color="red">mdi-thumb-down</v-icon>
          </v-btn>
        </template>
        <span>{{ $i18n.translate("Reject") }} {{ $i18n.translate("Claim") }}</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn fab small class="ml-2" v-on="on" @click="validateClaim()" :loading="isValidating || loading">
            <v-icon :color="validationResult ? validationResult.color : 'gray'">{{
              validationResult ? validationResult.icon : "mdi-progress-check"
            }}</v-icon>
          </v-btn>
        </template>
        <span>{{ $i18n.translate("Validate") }} {{ $i18n.translate("Claim") }}</span>
      </v-tooltip>
      <template v-slot:extension>
        <v-tabs v-model="tab">
          <v-tab>
            {{ $i18n.translate("Audit") }}
          </v-tab>
          <v-tab>
            {{ $i18n.translate("Overview") }}
          </v-tab>
          <v-tab>
            {{ $i18n.translate("Form") }}
          </v-tab>
          <v-tab> {{ $i18n.translate("Products") }} / {{ $i18n.translate("Payouts") }} </v-tab>
          <v-tab>
            {{ $i18n.translate("Documentation") }}
          </v-tab>
          <v-tab>
            {{ $i18n.translate("Timeline") }}
          </v-tab>
          <v-tab
            ><v-badge icon="mdi-exclamation-thick" color="error" offset-x="-2" v-if="notesCount > 0">Notes</v-badge>
            <span v-else>{{ $i18n.translate("Notes") }}</span></v-tab
          >
        </v-tabs>
      </template>
    </v-toolbar>
    <v-card-text>
      <ApiError :errors="errors" v-if="errors"></ApiError>
      <v-alert
        type="warning"
        :color="validationResult && validationResult.valid ? 'green' : undefined"
        v-if="validationResult"
      >
        <span v-if="!validationResult.valid">
          Validation failed.

          <ul>
            <li v-for="error of validationResult.errors" :key="Math.random() + error.property">
              {{ error.property }}: {{ error.message }}
            </li>
          </ul>
        </span>
        <span v-else>
          Validation successfull!
        </span>
      </v-alert>
      <v-stepper alt-labels class="no-box-shadow" v-if="claimStages" :loading="loading">
        <v-stepper-header>
          <template v-for="(cs, i) in claimStages">
            <v-stepper-step
              :editable="canEditClaimStage(cs)"
              :complete="claimStageIndex >= i"
              :step="i + 1"
              :key="i + '-stage'"
              @click="onChangeClaimStage(cs)"
              :loading="loading"
            >
              {{ cs ? cs.name : "" }}
              <span v-if="cs.claimStageType.name == 'APPROVED_FOR_PAYMENT'">
                ({{ $i18n.translate("Requires") }} {{ $i18n.translate("Approval") }})
              </span>
            </v-stepper-step>
            <v-divider :key="i + '-stage-divider'" v-if="i + 1 < claimStages.length"></v-divider>
          </template>
        </v-stepper-header>
      </v-stepper>
      <v-form :value="valid" @submit.prevent="onSubmit" v-model="valid">
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <ClaimAuditTab
              v-if="claimType && claimType.id"
              :claim="claim"
              :claimTypeId="claimType.id"
              :loading="loading"
              :uploads="claimUploads.existing"
              :valid="valid"
              @onSubmit="onSubmit"
              @onClaimProductError="onClaimProductError"
              @onClaimProductChange="onAuditChange"
              ref="auditTab"
            />
          </v-tab-item>
          <v-tab-item>
            <ClaimOverviewTab :byPage="byPage" :claim="claim" />
          </v-tab-item>
          <v-tab-item>
            <ClaimFormTab
              v-if="claimType && claimType.id"
              :claim="claim"
              :claimTypeId="claimType.id"
              :valid="valid"
              @onSubmit="onSubmit"
            />
          </v-tab-item>
          <v-tab-item>
            <v-row>
              <v-col cols="12">
                <v-row v-if="claim && claim.claimPayouts && claim.claimPayouts.length > 0" class="pa-5">
                  <h3>Claim Payouts:</h3>
                </v-row>
              </v-col>
            </v-row>

            <v-row
              ><ClaimPayoutTable
                v-if="claim && claim.claimPayouts && claim.claimPayouts.length > 0"
                :claim="claim"
              ></ClaimPayoutTable>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-row class="pa-5">
                  <h3>Claim Product Payouts:</h3>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <ClaimProductTable
                :claim="claim"
                @errors="onClaimProductError"
                :key="claimProductTableKey"
                :allowChangeAwardVehicle="
                  claimStage &&
                    claimStage.claimStageType &&
                    claimStage.claimStageType.name == 'AUDITING' &&
                    !claim.closed
                "
                :allowCRUDOnClaimProduct="true"
                @input="claimActivityTimeLineKey++"
                @change="onClaimProductChange"
              >
              </ClaimProductTable>
            </v-row>
          </v-tab-item>
          <v-tab-item>
            <v-container>
              <v-row>
                <v-col cols="6">
                  <v-card>
                    <v-card-title primary-title>
                      Downloadable Links
                    </v-card-title>
                    <v-card-text>
                      <ul v-for="(claimUpload, i) in claimUploads.existing" :key="i + '-uploads'">
                        <li>
                          <a :href="claimUpload.href" target="_blank" rel="noreferrer">{{
                            claimUpload.originalFilename
                          }}</a>
                          ({{ claimUpload.contentType }})
                        </li>
                      </ul>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="6">
                  <v-card>
                    <v-card-title primary-title>
                      Manipulate Uploads
                    </v-card-title>
                    <v-card-text>
                      <UploadField
                        v-model="claimUploads"
                        @input="onUpload"
                        :allowRemoval="!claim.closed || isGappInternalUser"
                        @newUpload="logNewUpload($event)"
                        @delete="logDeletion($event)"
                        :uploadOnBehalfOfParticipantId="participant ? participant.id : undefined"
                        :allowedfileExtensions="[
                          'jpg',
                          'jpeg',
                          'png',
                          'pdf',
                          'doc',
                          'docx',
                          'ppt',
                          'xls',
                          'xlsx',
                          'msg',
                          'heic'
                        ]"
                      ></UploadField>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </v-tab-item>
          <v-tab-item
            ><ActivityTimeline type="claim" :id="claim.id" :key="claimActivityTimeLineKey"></ActivityTimeline
          ></v-tab-item>
          <v-tab-item eager
            ><ClaimNotesTable
              v-if="claim && claim.id"
              :claim="claim"
              v-on:claimNote="claimActivityTimeLineKey++"
              :disabled="claim.closed"
              @updateCount="notesCount = $event"
            ></ClaimNotesTable
          ></v-tab-item>
        </v-tabs-items>
      </v-form>
      <v-dialog v-model="approveClaimDialog" max-width="500">
        <v-card>
          <v-card-title>Approve Claim?</v-card-title>

          <v-card-text><p>Are you sure you want to Approve this claim?</p></v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn :loading="loading" @click="approveClaimDialog = false">{{ $i18n.translate("Cancel") }}</v-btn>
            <v-btn class="primary" :loading="loading" @click="onApproveClaim"
              ><v-icon color="green" class="mr-2">mdi-thumb-up</v-icon> Approve Claim</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="rejectClaimDialog" max-width="500">
        <v-card>
          <v-card-title>Reject Claim?</v-card-title>

          <v-card-text
            ><p>Are you sure you want to Reject this claim?</p>

            <RejectionReasonField
              :selectedRejectReasonValue="selectedRejectClaimReason"
              :rejectReason="rejectClaimReason"
              :allowOther="true"
              @update:selectedRejectReason="updateRejectionReason"
              rejectionType="CLAIM"
            />
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn :loading="loading" @click="onCloseRejectClaimDialog()">{{ $i18n.translate("Cancel") }}</v-btn>
            <v-btn class="primary" :loading="loading" @click="onRejectClaim" :disabled="validRejectClaimReason"
              ><v-icon color="red" class="mr-2">mdi-thumb-down</v-icon> Reject Claim</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import ApiError from "../../../gapp-components/components/display/ApiError.vue";
import UploadField from "../../../gapp-components/components/fields/UploadField.vue";
import ActivityTimeline from "../../../gapp-components/components/tables/ActivityTimeline.vue";
import ClaimNotesTable from "../../../gapp-components/components/tables/ClaimNotesTable.vue";
import ClaimPayoutTable from "../../../gapp-components/components/tables/ClaimPayoutTable.vue";
import ClaimProductTable from "../../../gapp-components/components/tables/ClaimProductTable.vue";
import ClaimAuditTab from "./ClaimAuditTab";
import ClaimFormTab from "./ClaimFormTab";
import ClaimOverviewTab from "./ClaimOverviewTab";
import RejectionReasonField from "@/gapp-components/components/fields/RejectionReasonField.vue";

export default {
  components: {
    ClaimAuditTab,
    ClaimOverviewTab,
    ClaimFormTab,
    ApiError,
    ClaimProductTable,
    UploadField,
    ActivityTimeline,
    ClaimNotesTable,
    ClaimPayoutTable,
    RejectionReasonField
  },
  name: "CprClaimDetailManagement",
  metaInfo: {
    title: "Claim Detail"
  },
  data: () => ({
    tab: null,
    claimActivityTimeLineKey: 0,
    loading: true,
    valid: true,
    notesCount: 0,
    errors: {},
    byPage: false,
    claim: {},
    claimType: undefined,
    claimStage: {},
    participant: {},
    promotion: {},

    claimProducts: {
      existing: [],
      deleted: []
    },

    claimIdList: [],

    claimFields: [],
    claimFieldsByRow: [],
    claimFieldValues: {},

    isUpdated: false,

    claimStages: [],
    claimStageIndex: 0,
    nextclaimStageIndex: 0,
    nextClaimStage: undefined,

    claimUploads: {
      existing: [],
      deleted: []
    },
    currentPosition: null,
    isValidating: false,
    validationResult: null,
    approveClaimDialog: false,
    rejectClaimDialog: false,
    rejectClaimReason: "",
    selectedRejectClaimReason: "",
    claimProductTableKey: 0,
    isEditing: false,
    total: null
  }),
  async created() {
    this.currentPosition = this.$route.query.currentPosition;
    this.byPage = this.currentPosition != null ? true : false;

    if (this.$route.query.updated) {
      this.isUpdated = false;
      this.currentPosition = 0;
      await this.fetchClaimIdList();
      this.updateSearchParams();
    } else {
      await this.fetchClaimIdList();
    }
  },
  computed: {
    ...mapGetters(["selectedParticipant", "selectedClient", "selectedProgram"]),
    isGappInternalUser() {
      return this.$privilege.isGappInternalUser();
    },
    showPositionInfo() {
      return "Showing " + (parseInt(this.currentPosition) + 1) + " of " + this.total;
    },
    disableNextButton() {
      return parseInt(this.currentPosition) + 1 >= this.total;
    },
    hasReferenceNumbers() {
      return this.claimProducts && this.claimProducts.existing
        ? this.claimProducts.existing.some(cp => cp.payouts.some(cpp => cpp.referenceNumber))
        : false;
    },
    validRejectClaimReason() {
      if (this.selectedRejectClaimReason != "" && this.selectedRejectClaimReason.length > 0) {
        if (this.selectedRejectClaimReason == "Other" && this.rejectClaimReason != "") {
          return false;
        } else {
          if (this.selectedRejectClaimReason != "Other") {
            return false;
          }
        }
      }
      return true;
    }
  },
  methods: {
    updateRejectionReason({ selectedRejectReasonValue, rejectReason }) {
      this.selectedRejectClaimReason = selectedRejectReasonValue;
      this.rejectClaimReason = rejectReason;
    },
    async validateClaim() {
      this.isValidating = true;

      this.validationResult = (await this.$api.post("/api/claims/" + this.claim.id + "/validate")).data;

      if (this.validationResult && this.validationResult.valid) {
        this.validationResult.color = "green";
        this.validationResult.icon = "mdi-check-circle-outline";
        setTimeout(() => {
          this.validationResult = null;
        }, 5000);
      } else if (this.validationResult && !this.validationResult.valid) {
        this.validationResult.color = "red";
        this.validationResult.icon = "mdi-close-circle-outline";
      }

      this.isValidating = false;
    },
    getFiltersFromParams() {
      let filters = { submitted: true, closed: false };

      filters.approved = this.$route.query.approved;

      if (this.$route.query.closed) {
        filters.closed = this.$route.query.closed;
      }

      if (this.$route.query.keyword && this.$route.query.keyword.length > 0) {
        filters.keyword = this.$route.query.keyword;
      }

      if (this.$route.query.claimTypeId) {
        filters.claimType = { id: this.$route.query.claimTypeId };
      }

      if (this.$route.query.claimStageId) {
        filters.claimStage = { id: this.$route.query.claimStageId };
      }

      if (this.$route.query.organizationId) {
        filters.organization = { id: this.$route.query.organizationId };
      }

      if (this.$route.query.awardVehicleId) {
        filters.awardVehicle = { id: this.$route.query.awardVehicleId };
      }
      if (this.$route.query.followUp) {
        filters.followUp = this.$route.query.followUp;
      }

      return filters;
    },
    logNewUpload(upload) {
      if (upload && upload.id) {
        this.$api.post("/api/claims/" + this.claim.id + "/addActivity", {
          message: "Upload added: " + upload.originalFilename
        });
      }
    },
    logDeletion(upload) {
      if (upload && upload.id) {
        this.$api.post("/api/claims/" + this.claim.id + "/addActivity", {
          message: "Upload deleted: " + upload.originalFilename
        });
      }
    },
    async fetchClaimIdList() {
      this.loading = true;

      const { sortBy, sortDesc, total, page, itemsPerPage } = this.$route.query;

      let paramsFilters = this.getFiltersFromParams();
      try {
        let response;
        if (page && itemsPerPage) {
          response = await this.$api.post(
            "/api/claims/search/returnIds?size=" +
              itemsPerPage +
              "&page=" +
              (page - 1) +
              (sortBy && sortBy.length > 0 ? "&sort=" + sortBy + "," + (sortDesc ? "DESC" : "ASC") : ""),
            paramsFilters
          );
        } else {
          response = await this.$api.post(
            "/api/claims/search/returnIds?size=" +
              total +
              (sortBy && sortBy.length > 0 ? "&sort=" + sortBy + "," + (sortDesc ? "DESC" : "ASC") : ""),
            paramsFilters
          );
        }
        this.claimIdList = response.data;
        this.total = response.data.length;
        await this.fetchData();
      } catch (error) {
        this.loading = false;
      }
    },
    async fetchData() {
      this.loading = true;
      let claimId = null;

      if (this.byPage) {
        claimId = this.claimIdList[this.currentPosition];
      } else {
        claimId = this.$route.query.claim;
      }

      this.$api.get("/api/claims/" + claimId).then(({ data }) => {
        this.claim = data;
        this.breadcrumb();

        Promise.all([
          this.$api.getRelatedObject("participant", data).then(({ data }) => {
            this.participant = data;
          }),

          this.$api.getRelatedObject("claimStage", data).then(({ data }) => {
            this.claimStage = data;
          }),

          this.$api.getRelatedObject("uploads", data).then(({ data }) => {
            this.claimUploads.existing = data._embedded.uploads;
          }),

          this.$api.get("/api/claimFieldValues/byClaimId/" + data.id + "?size=500").then(({ data }) => {
            data.content.forEach(claimFieldValue => {
              this.claimFieldValues[claimFieldValue.claimFieldId] = claimFieldValue.value;
            });
          }),

          this.fetchClaimProducts(),
          this.$api.getRelatedObject("claimPayouts", data).then(({ data }) => {
            this.claim.claimPayouts = data?._embedded?.payouts;
          }),

          this.$api.getRelatedObject("promotion", data).then(({ data }) => {
            this.promotion = data;

            return Promise.all([
              this.$api.getRelatedObject("claimType", data).then(({ data }) => {
                this.claimType = data;

                return Promise.all([
                  this.$api
                    .get("/api/claimStages/byClaimTypeId/" + data.id + "?sort=rankOrder,ASC")
                    .then(({ data }) => {
                      this.claimStages = data.content;
                      this.claimStageIndex = this.claimStages.findIndex(
                        claimStage => claimStage.id == this.claimStage.id
                      );

                      // found claim stage
                      if (this.claimStageIndex > -1) {
                        // final stage
                        if (this.claimStageIndex == this.claimStages.length - 1) {
                          this.nextClaimStageIndex = this.claimStageIndex;
                          this.nextClaimStage = this.claimStages[this.nextClaimStageIndex];
                        } else {
                          this.nextClaimStageIndex = this.claimStageIndex + 1;
                          this.nextClaimStage = this.claimStages[this.nextClaimStageIndex];
                        }
                      }
                    }),
                  this.$api
                    .get("/api/claimFields/byClaimTypeId/" + data.id + "?size=500&sort=rankOrder,ASC")
                    .then(({ data }) => {
                      this.claimFields = data.content;
                      this.claimFieldsByRow = [];
                      let rowCount = -1;
                      let colCount = 0;
                      this.claimFields.forEach((claimField, i) => {
                        if (!claimField.cols || claimField.cols < 1 || claimField.cols > 12) {
                          claimField.cols = 12;
                        }
                        if (i == 0 || this.claimFields[i].category != this.claimFields[i - 1].category) {
                          claimField.newCategory = true;
                        } else {
                          claimField.newCategory = false;
                        }
                        if (claimField.newCategory || colCount + claimField.cols > 12) {
                          rowCount++;
                          colCount = claimField.cols;
                        } else {
                          colCount += claimField.cols;
                        }
                        if (!this.claimFieldsByRow[rowCount]) {
                          this.claimFieldsByRow[rowCount] = [];
                        }
                        this.claimFieldsByRow[rowCount].push(claimField);

                        if (claimField.defaultValue && claimField.defaultValue.length > 0) {
                          this.claimFieldValues[claimField.id] = claimField.defaultValue;
                        }
                      });
                    })
                ]);
              })
            ]);
          })
        ]).finally(() => {
          this.loading = false;
          this.claimActivityTimeLineKey++;
        });
      });
    },
    fetchClaimProducts() {
      return this.$api.get("/api/claimProducts/byClaimId/" + this.claim.id + "?size=500&page=0").then(({ data }) => {
        this.claimProducts.existing = data.content;
        this.forceRenderClaimProductTable();
      });
    },
    onApproveClaim() {
      this.loading = true;
      this.errors = {};
      let postForm = {};

      this.$api
        .post("/api/claims/" + this.claim.id + "/approve", postForm)
        .then(() => {
          this.fetchData();
        })
        .catch(error => {
          this.errors = this.$api.getErrorsFromResponse(error);
        })
        .finally(() => {
          this.loading = false;
          this.approveClaimDialog = false;
        });
    },
    onRejectClaim() {
      this.loading = true;
      this.errors = {};
      let postForm = {};
      postForm.reason =
        this.selectedRejectClaimReason == "Other" ? this.rejectClaimReason : this.selectedRejectClaimReason;
      postForm.emailTemplateParticipantForm = {
        emailTemplateKey: "END_USER_CLAIM_REJECTED"
      };
      this.$api
        .post("/api/claims/" + this.claim.id + "/reject", postForm)
        .then(() => {
          this.fetchData();
        })
        .catch(error => {
          this.errors = this.$api.getErrorsFromResponse(error);
        })
        .finally(() => {
          this.loading = false;
          this.onCloseRejectClaimDialog();
        });
    },
    onChangeClaimStage(cs) {
      if (this.canEditClaimStage(cs)) {
        this.loading = true;
        return this.$api
          .post("/api/claims/" + this.claim.id + "/stage/" + cs.id, {})
          .then(() => {
            return this.fetchData();
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    onUpload(uploads) {
      if (uploads && uploads.deleted) {
        uploads.deleted.map(document => {
          return this.$api.delete("/api/uploads/remove/" + document.id);
        });
      }
      this.$api.patch("/api/claims/" + this.claim.id, {
        uploads: uploads.existing.map(upload => this.$api.getSelfUrl("uploads", upload))
      });
    },
    canEditClaimStage(cs) {
      return cs.claimStageType.name == "AUDITING" && !this.claim.closed && !this.claim.approved && !this.claim.rejected;
    },
    breadcrumb() {
      this.$store.dispatch("setBreadcrumb", [
        {
          text: this.$i18n.translate("Dashboard"),
          to: { name: "dashboard" },
          exact: true
        },
        {
          text: "Admin"
        },
        {
          text: "Claim Audit Management",
          to: { name: "cprClaimAuditManagement" },
          exact: true
        },
        { text: "Claim " + this.claim.claimKey }
      ]);
    },
    onClaimProductError(value) {
      this.errors = value;
      setTimeout(() => {
        this.errors = {};
      }, 10000);
    },
    forceRenderClaimProductTable() {
      this.claimProductTableKey += 1;
    },
    getClaimFieldValues() {
      const claimFieldsClone = this.claimFields.slice();
      let cfValues = [];
      claimFieldsClone.map(claimField => {
        if (
          this.claimFieldValues[claimField.id] &&
          this.claimFieldValues[claimField.id].existing &&
          this.claimFieldValues[claimField.id].existing.length > 0
        ) {
          this.claimFieldValues[claimField.id] = this.claimFieldValues[claimField.id].existing[0];
        }

        let cfValue = {
          claimField: { id: claimField.id },
          value:
            this.claimFieldValues[claimField.id] && this.claimFieldValues[claimField.id].name
              ? this.claimFieldValues[claimField.id].name.trim()
              : this.claimFieldValues[claimField.id] && this.claimFieldValues[claimField.id].id
              ? this.claimFieldValues[claimField.id].id
              : this.claimFieldValues[claimField.id]
              ? this.claimFieldValues[claimField.id].trim()
              : this.claimFieldValues[claimField.id]
        };
        cfValues.push(cfValue);
      });
      return cfValues;
    },
    onSubmit(claimFieldValues) {
      if (claimFieldValues) {
        this.claimFieldValues = claimFieldValues;
      }
      this.loading = true;
      this.errors = {};
      let postForm = {};
      // only send claim fields during submission
      postForm.claimFieldValues = this.getClaimFieldValues();
      postForm.uploads = this.claimUploads.existing.map(upload => {
        return {
          id: upload.id
        };
      });
      this.$api
        .post("/api/claims/" + this.claim.id + "/update", postForm)
        .then(() => {
          this.isEditing = false;
          this.isUpdated = true;
          this.updateSearchParams();
        })
        .catch(error => {
          this.isUpdated = false;
          this.isEditing = true;
          this.updateSearchParams();
          this.errors = this.$api.getErrorsFromResponse(error);
        })
        .finally(() => {
          this.fetchData();
          this.loading = false;
        });
    },

    onEdit() {
      this.isEditing = !this.isEditing;
      if (!this.isEditing) {
        this.fetchData();
      }
    },
    onClaimProductChange() {
      this.fetchClaimProducts();
      this.$refs.auditTab.fetchClaimProducts();
    },
    onAuditChange() {
      this.fetchClaimProducts();
    },
    nextClaim() {
      this.currentPosition++;
      this.fetchData();

      this.updateSearchParams();
    },
    prevClaim() {
      this.currentPosition--;
      this.fetchData();

      this.updateSearchParams();
    },
    updateSearchParams() {
      const query = {
        ...this.$route.query,
        currentPosition: this.currentPosition,
        claim: this.claimIdList[this.currentPosition],
        updated: this.isUpdated
      };
      const newUrl = `${this.$route.path}?${new URLSearchParams(query).toString()}`;

      if (history.replaceState) {
        history.replaceState({ path: newUrl }, "", `#${newUrl}`);
      }
    },
    onCloseRejectClaimDialog() {
      this.rejectClaimDialog = false;
      this.selectedRejectClaimReason = "";
      this.rejectClaimReason = "";
    }
  }
};
</script>
